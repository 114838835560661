(function ($, Drupal, window, document, undefined) {

  var myLang = ($('body.i18n-en').length > 0) ? 'en' : 'it';

  function getQueryString(key){
    key = key.replace(/[*+?^$.\[\]{}()|\\\/]/g, "\\$&"); // escape RegEx meta chars
    var match = location.search.match(new RegExp("[?&]"+key+"=([^&]+)(&|$)"));
    return match && decodeURIComponent(match[1].replace(/\+/g, " "));
  }

  function tabsSetFirstActive(parag){
    parag.find('.tabsHeaders a:first').addClass('current');
    parag.find('.tabsContents .tabCnt:first').addClass('current').show();
    parag.find('.tabsContents p.tabMobiTit:first').addClass('current');
  }

  Drupal.behaviors.noOnceBehaviors = {
    attach: function(context, settings){
      $(document).foundation('equalizer', 'reflow');

      $('.form-checkbox').once('icheckBehaviors', function(){
        $(this).icheck({
          autoAjax: true
        });
      });

      /*
       * Educational info link
       */
      $(".edu-info a.pnpl-info, .edu-title-wrap").once('infoeduBehaviors', function(){
        $(this).on('click', function(e){
          e.preventDefault();
          $(this).closest('.node-educational').find('.edu-footer').slideToggle(400);
        });

      });

      if($('.l-supfooter').css('display') !== 'none') {
        $(".l-supfooter .webform-client-form .form-select").each(function(){
          if( $(this).hasClass('error')) {
            $(this).select2({
              language: myLang,
              containerCssClass : "error",
              placeholder: $(this).find("option:first").text()
            });
            $('.select2 span').addClass('needsclick');
          } else {
            $(this).select2({
              language: myLang,
              placeholder: $(this).find("option:first").text(),
            });
            $('.select2 span').addClass('needsclick');
          }
        });
      }

      if($('.l-supfooter').css('display') === 'none') {
        $('.pnpl-email-filled').on('click', function(e){
          var teamemail = $(this).attr('data-email');
          $('.webform-client-form .webform-component--team-email input').val(teamemail);
        });


        $(".l-supfooter .webform-client-form .form-select").each(function(){
          //console.log("teamwebform");
          if( $(this).hasClass('error')) {
            $(this).select2({
              language: myLang,
              containerCssClass : "teamwebformcontainererror",
              placeholder: $(this).find("option:first").text(),
              dropdownCssClass: "teamwebformdropdown"
            });
            $('.select2 span').addClass('needsclick');
          } else {
            $(this).select2({
              language: myLang,
              placeholder: $(this).find("option:first").text(),
              containerCssClass: "teamwebformcontainer",
              dropdownCssClass: "teamwebformdropdown"
            });
            $('.select2 span').addClass('needsclick');
          }
        });
      }


      $(".views-exposed-form .form-select").each(function(){
        var allowClear = '',
         minimumResultsForSearch = '';

        //console.log($(this).attr('id'));

        switch (true) {
          case /^edit-field-ptr-sector-tid-selective.*/.test($(this).attr('id')):
            allowClear = false;
            minimumResultsForSearch = -1;
            if($(this).val() !== 'All') {
              allowClear = true;
            }
            break;
          case /^edit-field-edu-type-tid.*/.test($(this).attr('id')):
            allowClear = false;
            minimumResultsForSearch = -1;
            if($(this).val() !== 'All') {
              allowClear = true;
            }
            break;
          case /^edit-sort-bef-combine.*/.test($(this).attr('id')):
            allowClear = false;
            minimumResultsForSearch = -1;
            break;
          case /^edit-field-tipo-news.*/.test($(this).attr('id')):
            allowClear = false;
            minimumResultsForSearch = -1;
            if($(this).val() !== 'All') {
              allowClear = true;
            }
            break;
          case /^edit-field-anno-value.*/.test($(this).attr('id')):
            allowClear = true;
            minimumResultsForSearch = -1;
            if($(this).val() !== '') {
              allowClear = true;
            }
            break;
          case /^edit-field-mese-value.*/.test($(this).attr('id')):
            allowClear = true;
            minimumResultsForSearch = -1;
            if($(this).val() !== '') {
              allowClear = true;
            }
            break;

        }

        $(this).select2({
          language: myLang,
          allowClear: allowClear,
          minimumResultsForSearch: minimumResultsForSearch,
          placeholder: $(this).find("option:first").text(),
          width: 'resolve',
          dropdownCssClass: 'exposed-dropdown',
          searchInputPlaceholder: Drupal.t('Search')
        }).on('select2:unselecting', function() {
          $(this).data('unselecting', true);
        }).on('select2:opening', function(e) {
          if ($(this).data('unselecting')) {
            $(this).removeData('unselecting');
            e.preventDefault();
          }
        });
        $('.select2 span').addClass('needsclick');
      });

      if( $('.paragraphs-item-homepage-paragraph').length > 0 && $('body').hasClass("not-front") ) {
        $('.paragraphs-item-homepage-paragraph').find(".hpTitle").removeClass("hpTitle");
      }
    }
  };

  Drupal.behaviors.globalBehaviors = {
    attach: function(context, settings){
      $('body').once('globalBehaviors', function(){

        /*
         * Desktop menu
         */
        $(".desktopMenu ul.menu li.expanded").mouseenter( function(){
          $(this).find('ul.menu').fadeIn(400);
        }).mouseleave( function(){
          $(this).find('ul.menu').fadeOut(600);
        });


        $('a').on('click', function(e){
          if($(this).attr('href') === '#scrolltoform') {
            e.preventDefault();
            $("html, body").animate({ scrollTop: $('.l-supfooter').offset().top - 200 }, 500);
          }
        });

        /**
         * Se passato un parametro tramite query scorro al punto desiderato
         */
        var gotoString = getQueryString('gotostring');
        if(gotoString) {
          var gotoItem  = null,
              gotoTab   = false;

          // Cerco l'elemento
          if($("a[name='" + gotoString + "']").length === 1){
            // Anchor con il name indicato (inserito tramite WYSIWYG)
            gotoItem = $("a[name='" + gotoString + "']");
          }else if($("a[data-tabkey='" + gotoString + "']").length === 1){
            // Tab con attribute autogenerato (inserito da tpl)
            gotoItem  = $("a[data-tabkey='" + gotoString + "']");
            gotoTab   = true;
          }

          // Scrollo
          if(gotoItem){

            setTimeout(function(){

              var gotoOffset  = gotoItem.offset().top,
                  headerH     = $(".l-header").height(),
                  delta       = 150;

              var gotoPoint   = gotoOffset - headerH - delta;

              //Click sul link per aprire il tab
              if(gotoTab){ gotoItem.trigger("click"); }

              $("html, body").animate({ scrollTop: gotoPoint }, 500);
            }, 1000);
          }
        }

        //Paragraph Slider
        $(".entity-paragraphs-item.paragraphs-item-slider").each(function(index){
          var mySlider  = $(this).find('.sliderItemsWrapper > .field-items'),
              nItems    = mySlider.find('> .field-item').length;

          if(nItems > 1){
            mySlider.slick({
              dots: false,
              arrows: false,
              autoplay: true,
              autoplaySpeed: 4000,
              infinite: true,
              slidesToShow: 3,
              slidesToScroll: 1,
              slide: '.sliderItem',
              responsive: [
                {
                  breakpoint: 1024,
                  settings: { slidesToShow: 2 }
                },
                {
                  breakpoint: 600,
                  settings: { slidesToShow: 1 }
                }
              ]
            });
          }
        });

        //Paragraph Compara testi
        $(".entity-paragraphs-item.paragraphs-item-compara-testi").each(function(index){
          var thisIndicator = $(this).find('.indicator'),
              mySlider      = $(this).find('.compSlider'),
              myUniqueId    = parseInt($(this).find(".indicator").attr('data-uniqueid')),
              myMask        = $(this).find("svg #clipHalf-"+myUniqueId+" rect");

          //Slider tra i due contenuti
          mySlider.slick({
            dots: false,
            arrows: false,
            autoplay: false,
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            slide: '.compText'
          });

          //Callback per animare la mask al cambio slide
          mySlider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
            var newX  = (nextSlide === 0) ? 0 : 369;
            TweenMax.to(myMask, 0.4, {x:newX, ease: Power3.easeOut});
          });

          //Click sul titolo per cambiare slide
          $(this).find('.compHeader .compTit').on("click", function(e){
            e.preventDefault();
            var gotoSlide = parseInt($(this).attr('data-scroll'));
            mySlider.slick('slickGoTo', gotoSlide, false);
          });
        });

        //Paragraph Tabs wrapper
        $(".entity-paragraphs-item.paragraphs-item-tabs-wrapper").each(function(index){

          tabsSetFirstActive($(this));

          $(".tabsHeaders a, .tabsContents p.tabMobiTit").on("click", function(e){
            e.preventDefault();

            if(!$(this).hasClass('current')){
              var myWrap  = $(this).closest('.tabsWrapper'),
                  tabIdx  = parseInt($(this).attr('data-tabindex')),
                  newPTit = myWrap.find('.tabsContents p.tabMobiTit[data-tabindex="'+tabIdx+'"]'),
                  newHTit = myWrap.find('.tabsHeaders a[data-tabindex="'+tabIdx+'"]'),
                  newCnt  = myWrap.find('.tabsContents .tabCnt[data-tabindex="'+tabIdx+'"]'),
                  oldPTit = myWrap.find('.tabsContents p.tabMobiTit.current'),
                  oldHTit = myWrap.find('.tabsHeaders a.current'),
                  oldCnt  = myWrap.find('.tabsContents .tabCnt.current');

              oldPTit.removeClass('current');
              oldHTit.removeClass('current');
              oldCnt.removeClass('current').slideUp(300, function(){
                newPTit.addClass('current');
                newHTit.addClass('current');
                newCnt.addClass('current').slideDown(300);
              });
            }
          });
        });

        //Paragraph Trending topic
        $(".paragraphs-item-trending-topics").each(function(index){
          var mySlider  = $(this).find('.field-name-field-topic > .field-items'),
              nItems    = mySlider.find('> .field-item').length,
              myNextBtn = $(this).find('.arrow-right'),
              myPrevBtn = $(this).find('.arrow-left');

          if(nItems > 1){
            mySlider.slick({
              dots: false,
              arrows: true,
              autoplay: true,
              autoplaySpeed: 4000,
              infinite: true,
              fade: true,
              prevArrow: myNextBtn,
              nextArrow: myPrevBtn,
              cssEase: 'linear',
              slidesToShow: 1,
              slidesToScroll: 1,
              slide: '.field-item'
            });
          }
        });

        /**
         * View HP "Product sliders"
         */
        $(".view-id-homepage.view-display-id-productshp").each(function(index){
          var myParag   = $(this),
              mySlider  = myParag.find(".view-content"),
              mySlides  = mySlider.find(".views-row");

          if(mySlides.length > 1){

            //Avvio la slider
            mySlider.slick({
              autoplay: false,
              //autoplaySpeed: 4000,
              arrows: false,
              dots: true,
              infinite: true,
              slide: '.views-row',
              slidesToShow: 1,
              slidesToScroll: 1,
              appendDots: $('.preliosnpl_utils-preliosnpl_blk_hpanim'),
              customPaging : function(slider, i) {
                var blstring = $(slider.$slides[i]).find('.slider-nav').text();
                var titlestring = blstring.substring(7);
                return titlestring;
             }
            });

          }
        });


        $(document).on("click",".block-preliosnpl-utils-preliosnpl-blk-hpanim .slick-dots", function(e) {
          $("html, body").animate({ scrollTop: $(".block-preliosnpl-utils-preliosnpl-blk-hpanim .slick-dots").offset().top - 108 }, 500);
        });

        //Paragraph Slider image
        $(".paragraphs-item-slider-image").each(function(index){
          var mySlider  = $(this).find('.content'),
              nItems    = mySlider.find('> .slideImage').length,
              myNextBtn = $(this).find('.arrow-right'),
              myPrevBtn = $(this).find('.arrow-left');

          if(nItems > 1){
            mySlider.slick({
              dots: false,
              arrows: true,
              autoplay: true,
              autoplaySpeed: 4000,
              infinite: true,
              prevArrow: myNextBtn,
              nextArrow: myPrevBtn,
              slidesToShow: 2,
              slidesToScroll: 1,
              slide: '.slideImage',
              responsive: [
                {
                  breakpoint: 1024,
                  settings: { slidesToShow: 2 }
                },
                {
                  breakpoint: 600,
                  settings: { slidesToShow: 1 }
                }
              ]
            });
          }
        });

        /**
         * Paragraph "Highlights sliders"
         * - se più di una slide faccio partire la slider
         */
        $(".paragraphs-item-highlights-sliders").each(function(index){
          var myParag   = $(this),
              arrWrp    = myParag.find(".highlightsSliderArrows"),
              myPrevArr = myParag.find(".highlightsSliderArrows .hsaPrev"),
              myNextArr = myParag.find(".highlightsSliderArrows .hsaNext"),
              mySlider  = myParag.find(".highlightsSliderContents"),
              mySlides  = mySlider.find(".hsSlide");

          if(mySlides.length > 1){

            //All'avvio nascondo o mostro le frecce in base al numero di elementi
            mySlider.on('init', function(event, slick){
              var nSlide = parseInt(slick.slideCount);
              arrWrp.hide();

              if($(window).width() < 768){ arrWrp.show();
              }else if(nSlide > 3){ arrWrp.show(); }
            });

            //Avvio la slider
            mySlider.slick({
              autoplay: false, //true,
              autoplaySpeed: 4000,
              arrows: true,
              prevArrow: myPrevArr,
              nextArrow: myNextArr,
              dots: false,
              infinite: true,
              slide: '.hsSlide',
              slidesToShow: 3,
              slidesToScroll: 1,
              responsive: [
                {
                  breakpoint: 768,
                  settings: { slidesToShow: 1 }
                }
              ]
            });

            //Al cambio di breakpoint nascondo o mostro le frecce in base al numero di elementi
            mySlider.on('breakpoint', function(event, slick, breakpoint){
              var nSlide = parseInt(slick.slideCount);
              arrWrp.hide();

              if(breakpoint === 768){ arrWrp.show();
              }else if(nSlide > 3){ arrWrp.show(); }
            });
          }
        });



        /**
         * View HP "Partners sliders"
         */
        $(".view-id-partners.view-display-id-hp").each(function(index){
          var myParag   = $(this),
              mySlider  = myParag.find(".view-content"),
              mySlides  = mySlider.find(".views-row");

          if(mySlides.length > 1){

            //Avvio la slider
            mySlider.slick({
              autoplay: true,
              autoplaySpeed: 2000,
              arrows: false,
              dots: true,
              infinite: true,
              slide: '.views-row',
              slidesToShow: 3,
              slidesToScroll: 1,
              responsive: [
                {
                  breakpoint: 768,
                  settings: { slidesToShow: 1 }
                }
              ]
            });

          }
        });

      });
    }
  };

  Drupal.behaviors.menuBehaviors = {
    attach: function(context, settings){
      $("body").once("menumobileBehaviors", function(){

        var mmenuIco  = $(".preliosHeader .pnpl-after-hamburger"),
            mmenuSel  = $("#right-off-canvas-menu .menu-block-wrapper"),
            header    = $(".l-header");

        mmenuSel.mmenu({
          navbar: {
            "add": true,
            "title": '',
            "titleLink": 'none'
          },
          extensions: [
            "fullscreen",
            "position-front",
            "position-top",
            "fx-panels-slide-100",
            "fx-listitems-slide"
          ],
          counters: false,
          slidingSubmenus: true
        }, {
          selectedClass: "Selected",
          classNames: {
            selected: "active",
            fixedElements: {
               fixed: "fix",
               sticky: "stick"
            }}
        }).on('init', function(){
            $('body').append($('#admin-menu'));
        })
        .trigger( "init" );

        var API = mmenuSel.data("mmenu");
        if(API){
          mmenuIco.on("click", function(e){
            e.preventDefault();
            if($(this).hasClass('is-active')){
              header.removeClass('mmenu-opened');
              API.close();
            }else{
              header.addClass('mmenu-opened');
              API.open();
            }
            return false;
          });

          API.bind("open:finish", function(){
            setTimeout(function(){ mmenuIco.addClass("is-active"); }, 100);
          });
          API.bind("close:finish", function(){
            setTimeout(function(){ mmenuIco.removeClass("is-active"); }, 100);
          });

        }

      });
    }
  };

  Drupal.behaviors.outdatedBrowser = {
    attach: function(context, settings) {
			$(document).ready(function(){
        var myLang = ($('body.i18n-en').length > 0) ? 'en' : 'it',
            myPath  = Drupal.settings.basePath + Drupal.settings.pathToTheme;

				outdatedBrowser({
					bgColor:      '#133e8a',
					color:        '#ffffff',
					lowerThan:    'borderImage', //IE10 non accettato
					languagePath: myPath+'/outdatedbrowser/lang/'+myLang+'.html'
				});
			});
		}
  };

})(jQuery, Drupal, this, this.document);

